.footer {
  margin: 0;
  padding: 1rem;
  background-color: whitesmoke;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 150px;
}

.contact {
  padding-right: 20px;
}
