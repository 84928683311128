.feed {
  flex: 0.6;
  margin: 0 20px;
  background-color: white !important;
  padding-top: 200px;
  justify-content: center;
  align-items: center;
}

.feed > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.feed__inputContainer {
  background-color: white;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 500px;
}

.feed__input {
  border: 1px solid lightgray;
  border-radius: 30px;
  display: flex;
  padding: 10px;
  color: gray;
  padding-left: 15px;
  align-items: center;
}

.feed__input > form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.feed__input > form > input {
  border: none;
  flex: 1;
  margin-left: 10px;
  outline-width: 0;
  font-weight: 600;
  padding: 10px;
}

.feed__input > form > button {
  display: none;
}

.feed__description {
  margin-top: 20px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid gray;
}
.feed__description > p,
a {
  font-size: 14px;
}

.feed__description > h4 {
  font-size: 14px;
}
